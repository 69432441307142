import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

import ChannelResults from './ChannelResults';
import UserResults from './UserResults';
import MessageResults from './MessageResults';

import useSearchParams from 'src/utils/hooks/useSearchParams';
import type { QuerySearchParamsType } from '../../types/searchTypes';
import type { IChannel, IMessage, IUser } from 'src/types';

const SearchResultsDropdown: React.FC<{
  userData: {
    list: IUser[];
    isMoreButtonVisible: boolean;
    isLoading?: boolean;
  };
  channelData: {
    list: IChannel[];
    isMoreButtonVisible: boolean;
    isLoading?: boolean;
  };
  messageData: {
    list: IMessage[];
    isMoreButtonVisible: boolean;
    isLoading?: boolean;
  };
  handleClose: () => void;
  className?: string;
  resultsWrapperClassName?: string;
}> = (props) => {
  const [params] = useSearchParams<QuerySearchParamsType>();

  return (
    <StyledSearchResultsDropdown className={props.className}>
      <div className={cn('results__wrapper', props.resultsWrapperClassName)}>
        <UserResults
          userResults={props.userData.list}
          searchResult={params.search?.trim()}
          handleClose={props.handleClose}
          value={params.search}
          isVisibleMoreButton={props.userData.isMoreButtonVisible}
          isLoading={props.userData.isLoading}
        />

        <ChannelResults
          channelsResults={props.channelData.list}
          handleClose={props.handleClose}
          value={params.search}
          isVisibleMoreButton={props.channelData.isMoreButtonVisible}
          isLoading={props.channelData.isLoading}
        />

        <MessageResults
          messagesResults={props.messageData.list}
          value={params.search}
          handleClose={props.handleClose}
          isVisibleMoreButton={props.messageData.isMoreButtonVisible}
          isLoading={props.messageData.isLoading}
        />
      </div>
    </StyledSearchResultsDropdown>
  );
};

const StyledSearchResultsDropdown = styled.div`
  position: absolute;
  z-index: 100;
  top: 60px;
  left: 16px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 12px;
  padding: 2px;

  .results__wrapper {
    background-color: ${({ theme }) => theme.palette.background.paper};
    border-radius: 12px;
    width: 310px;
    min-height: 250px;
    max-height: 610px;
    overflow-y: auto;
    margin-right: 2px;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }
  
    &::-webkit-scrollbar-thumb {
      border-radius: 12px;
      background-color: ${({ theme }) => theme.palette.divider};
    }
  }
`;

export default SearchResultsDropdown;
