import type { IMessage, PreviewMessagesType } from 'src/types';
import type { IChatStore } from '../types';

export class MessageEntityService {
  static updatePinnedMessageEntity(
    store: IChatStore,
    data: {
      channelId: number;
      changes: Partial<IMessage> & { messageId: number };
    },
  ) {
    if (!store.channelDetailsObject?.[data.channelId]?.pinnedMessages) {
      return;
    }

    store.channelDetailsObject[data.channelId].pinnedMessages =
      store.channelDetailsObject[data.channelId].pinnedMessages.map((message) => {
        if (message.messageId !== data.changes.messageId) {
          return message;
        }
        return {
          ...message,
          ...data.changes,
        };
      });
  }

  static updateThreadMessageEntity(
    store: IChatStore,
    data: {
      messageId: number;
      parentMessageId: number;
      channelId: number;
      changes: Partial<IMessage>;
    },
  ) {
    const oldMessage = store.threads[data.parentMessageId]?.messagesObject[data.messageId];

    if (oldMessage) {
      const updatedMessage: IMessage = {
        ...oldMessage,
        ...data.changes,
      };
      store.threads[data.parentMessageId].messagesObject[data.messageId] = updatedMessage;
      this.updatePinnedMessageEntity(store, {
        changes: updatedMessage,
        channelId: data.channelId,
      });
      return;
    }

    this.updatePinnedMessageEntity(store, {
      changes: { ...data.changes, messageId: data.messageId },
      channelId: data.channelId,
    });
  }

  static updateCommonMessageEntity(
    store: IChatStore,
    data: {
      messageId: number;
      channelId: number;
      changes: Partial<IMessage>;
    },
  ) {
    const oldMessage = store.channelDetailsObject[data.channelId]?.channelMessagesObject?.[data.messageId];

    if (oldMessage) {
      const updatedMessage: IMessage = {
        ...oldMessage,
        ...data.changes,
      };
      store.channelDetailsObject[data.channelId].channelMessagesObject[data.messageId] = updatedMessage;
      this.updatePinnedMessageEntity(store, {
        changes: updatedMessage,
        channelId: data.channelId,
      });
      return;
    }

    this.updatePinnedMessageEntity(store, {
      changes: { ...data.changes, messageId: data.messageId },
      channelId: data.channelId,
    });
  }

  static updateMessageEntity(
    store: IChatStore,
    data: {
      messageId: number;
      parentMessageId?: number | null;
      channelId: number;
      changes: Partial<IMessage>;
    },
  ) {
    if (data.parentMessageId) {
      this.updateThreadMessageEntity(store, { ...data, parentMessageId: data.parentMessageId });
    } else {
      this.updateCommonMessageEntity(store, data);
    }
  }

  static getMessageEntity(store: IChatStore, data: {
    messageId: number;
    parentMessageId?: number | null;
    channelId: number;
  }): IMessage | undefined {
    if (data.parentMessageId) {
      return store.threads[data.parentMessageId]?.messagesObject?.[data.messageId];
    }
    return store.channelDetailsObject[data.channelId]?.channelMessagesObject?.[data.messageId];
  }

  static getPreviewMessagesBasedOnIds(store: IChatStore, data: {
    messageId: number;
    parentMessageId?: number;
    channelId: number;
    previewMessageIds: number[] | undefined;
  }) {
    const message = this.getMessageEntity(store, {
      messageId: data.messageId,
      parentMessageId: data.parentMessageId,
      channelId: data.channelId,
    });

    const existingValues = Object.fromEntries(
      message?.previewMessages?.map((previewMessage) => [previewMessage.id, previewMessage]) || [],
    );

    return data.previewMessageIds?.map((id): PreviewMessagesType => {
      if (existingValues[id]) {
        return existingValues[id];
      }
      return {
        id,
        visible: true,
      };
    });
  }
}
