import qs from 'query-string';
import getStore from 'src/store/getStore';

export interface IChatRouteParams {
  channelId: number | string;
  messageId?: number | string;
  parentMessageId?: number | string;
}

class ChatRouter {
  private baseUrl = '/chat';

  public getChatUrl(params: {
    channelId: number | string;
    messageId?: number | string | null;
    parentMessageId?: number | string | null;
  }) {
    const companyId = getStore().main.user?.companyId;

    const queryParams = qs.stringify({
      parentMessageId: params.parentMessageId,
      messageId: params.messageId,
      ci: companyId,
    }, { skipEmptyString: true, skipNull: true });

    return `${this.baseUrl}/${params.channelId}${queryParams ? `?${queryParams}` : ''}`;
  }

  public isSamePath(pathNameLeft: string, pathNameRight: string) {
    return pathNameLeft === pathNameRight;
  }

  public isChatPage(pathName: string) {
    return pathName.startsWith(this.baseUrl);
  }
}

export default new ChatRouter();
